import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import styles from './Contact.module.css';
import emailjs from "emailjs-com";
import ozdobaContact from "./4.png";
import ozdobaContact2 from "./2.png";

const Contact = () => {
  const [ref, inView] = useInView({
    triggerOnce: true, 
  });

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Sprawdź, czy wszystkie pola są uzupełnione
    if (!name || !email || !message) {
      alert("Uzupełnij wszystkie pola!");
      return;
    }

    emailjs
      .send(
        "service_ahgte07",
        "template_5xnvcej",
        {
          from_name: name,
          from_email: email,
          message: message,
        },
        "7eTIxZGRuLahAgznd"
      )
      .then((response) => {
        console.log("E-mail wysłany:", response);
        setIsSubmitted(true);
      })
      .catch((error) => {
        console.error("Błąd podczas wysyłania e-maila:", error);
      });

    setName("");
    setEmail("");
    setMessage("");
  };

  return (
    <div id="kontakt" className={styles.container}>
      <div ref={ref} className={`image ${styles.leftColumnContact} ${inView ? styles.slideIn : ''}`}>
        <img className={`${styles.ozdobaContact} ozdobaContact`} src={ozdobaContact} alt="szlaczek, ozdoba zdjęcia" />
        <img className={styles.ozdobaContact2} src={ozdobaContact2} alt="szlaczek, ozdoba zdjęcia" />
        <h1>Skontaktuj się z nami!</h1>
      </div>
      <div className={`image ${styles.rightColumnContact} ${inView ? styles.slideIn1 : ''}`}>
        <form onSubmit={handleSubmit}>
          <label htmlFor="fullName">Imię i nazwisko</label>
          <input type="text" id="fullName" name="fullName" placeholder="Imię i nazwisko" onChange={handleNameChange} />

          <label htmlFor="email">Adres e-mail</label>
          <input type="email" id="email" name="email" placeholder="Adres e-mail"  onChange={handleEmailChange} />

          <label htmlFor="phone">Nr telefonu</label>
          <input type="tel" id="phone" name="phone" placeholder="Nr telefonu" onChange={() => {}} />

          <label htmlFor="message">Treść wiadomości</label>
          <textarea id="message" name="message" placeholder="Treść wiadomości"  value={message} onChange={handleMessageChange}></textarea>
          <div className={styles.buttonWrapper}>
            <button className={styles.btn} type="submit">Wyślij</button>
          </div>
        </form>
        {isSubmitted && (
          <div className={styles.sendMailWrapper}>
            <p className={styles.sendMail}>
              Dziękuję za przesłanie wiadomości. Odpowiemy najszybciej jak to możliwe!
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Contact;
