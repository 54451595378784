import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import styles from './Cookie.module.css';
import Polityka from '../polityka/Polityka'; // Poprawienie ścieżki do komponentu Polityka

const CookieModal = () => {
  const [cookies, setCookie] = useCookies(['cookieConsent']);
  const [showModal, setShowModal] = useState(!cookies.cookieConsent);
  const [showPolityka, setShowPolityka] = useState(false);

  const handleAcceptCookies = () => {
    setCookie('cookieConsent', true, { path: '/' });
    setShowModal(false);
  };

  const handleShowPolityka = () => {
    setShowPolityka(true);
  };

  const handleClosePolityka = () => {
    setShowPolityka(false);
  };

  return (
    <>
      {showModal && (
        <div className={styles.cookieModalOverlay}>
          <div className={styles.CookieModal}>
            <p>
              Cenimy prywatność użytkowników. Używamy plików cookie, aby poprawić jakość przeglądania, wyświetlać reklamy lub treści dostosowane do indywidualnych potrzeb użytkowników oraz analizować ruch na stronie. Kliknięcie przycisku „Akceptuj" oznacza zgodę na wykorzystywanie przez nas{' '}
              <span onClick={handleShowPolityka} className={styles.linkToPolityka}>
                plików cookie.
              </span>
            </p>
            <button className={styles.cookieBtn} onClick={handleAcceptCookies}>
              Akceptuj
            </button>
          </div>
        </div>
      )}

      {showPolityka && (
        <div className={styles.overlay} onClick={handleClosePolityka}>
          <div className={styles.modal}>
            <Polityka />
          </div>
        </div>
      )}
    </>
  );
};

export default CookieModal;
