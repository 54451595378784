import React from 'react';
import { useInView } from 'react-intersection-observer';
import styles from './Advantages.module.css';
import kropka from "./5.png"
import kropka2 from "./6.png"
import kropka3 from "./7.png"

const Advantages = () => {
  
  const [ref, inView] = useInView({
    triggerOnce: true, 
  });


  return (
    <div id="advantages" className={styles.container}>
      <div className={styles.headerAdvantages }>
        <div>
      <hr ref={ref} className={styles.hrLine} />
      </div>
      <h1>Najważniejsze cechy naszych stron</h1>
      </div>
      <div
        className={styles.advantagesWrapper}
      >
        <div className={`image ${styles.feature} ${inView ? styles.slideIn : ''}`}>
          <img className={styles.kropka} src={kropka} alt="szlaczek, ozdoba zdjęcia" />
          <h4>OPTYMALIZACJA SEO</h4>
          <p>Nasze strony są przyjazne dla wyszukiwarek, co pomaga w lepszym pozycjonowaniu w wynikach Google i zwiększa widoczność Twojej strony w sieci.</p>
        </div>
        <div className={`image ${styles.feature} ${inView ? styles.slideIn1 : ''}`}>
          <img className={styles.kropka2} src={kropka2} alt="szlaczek, ozdoba zdjęcia" />
          <h4>RESPONSYWNOŚĆ</h4>
          <p>Nasze strony są w pełni responsywne, co oznacza, że dostosowują się do różnych rozmiarów ekranów i urządzeń, zapewniając doskonałe wrażenia użytkownika na komputerach, tabletach i smartfonach.</p>
        </div>
        <div className={`image ${styles.feature} ${inView ? styles.slideIn2 : ''}`}>
          <img className={styles.kropka3} src={kropka3} alt="szlaczek, ozdoba zdjęcia" />
          <h4>TWORZENIE STRONY OD PODSTAW</h4>
          <p>Nie korzystamy z gotowych kreatorów stron. Każda strona, którą projektujemy, jest tworzona od podstaw, co pozwala nam dostosować ją do Twoich unikalnych potrzeb i wizji, gwarantując wyjątkowy wygląd i funkcjonalność.</p>
        </div>
      </div>
    </div>
  );
};

export default Advantages;
