import React from 'react';
import styles from './Top.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useInView } from 'react-intersection-observer';

const Top = () => {
  const [ref, inView] = useInView({
    triggerOnce: true, 
  });

  return (
    <div className={styles.containerTop}>
      <p ref={ref} className={`icon ${styles.icon} ${inView ? styles.slideIn : ''}`}>
        <FontAwesomeIcon icon={faArrowRight} beatFade style={{ color: "#f7fffd" }} />  &nbsp;Szybka realizacja
      </p>
       <p className={`icon ${styles.icon} ${inView ? styles.slideIn : ''}`}>
        <FontAwesomeIcon icon={faArrowRight} beatFade style={{ color: "#f7fffd" }} />  &nbsp;Indywidualny projekt
      </p>
       <p className={`icon ${styles.icon} ${inView ? styles.slideIn : ''}`}>
        <FontAwesomeIcon icon={faArrowRight} beatFade style={{ color: "#f7fffd" }} />  &nbsp;Trendy
      </p>
       <p className={`icon ${styles.icon} ${inView ? styles.slideIn : ''}`}>
        <FontAwesomeIcon icon={faArrowRight} beatFade style={{ color: "#f7fffd" }} />  &nbsp;Fotografia
      </p>
       <p className={`icon ${styles.icon} ${inView ? styles.slideIn : ''}`}>
        <FontAwesomeIcon icon={faArrowRight} beatFade style={{ color: "#f7fffd" }} />  &nbsp;Nowoczesny design
      </p>
       <p className={`icon ${styles.icon} ${inView ? styles.slideIn : ''}`}>
        <FontAwesomeIcon icon={faArrowRight} beatFade style={{ color: "#f7fffd" }} />  &nbsp;Wsparcie techniczne
      </p>
    </div>
  );
};

export default Top;
