import React, { useState } from 'react';
import styles from './Reviews.module.css';
import person1 from './aniela1.webp';
import person2 from './natalia1.webp';
import person3 from './wojtek1.webp';
import person4 from './asia1.webp';
import { useInView } from 'react-intersection-observer';
import { useMediaQuery } from 'react-responsive';

const Reviews = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const scrollLeft = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  const scrollRight = () => {
    const totalReviews = 4;
    setCurrentIndex((prevIndex) => (prevIndex < totalReviews - 1 ? prevIndex + 1 : prevIndex));
  };

  const scrollLeftL = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  const scrollRightL = () => {
    const totalReviews = 4;
    setCurrentIndex((prevIndex) => (prevIndex < totalReviews - 3 ? prevIndex + 1 : prevIndex));
  };

  const [ref, inView] = useInView({
    triggerOnce: true, 
  });


  return (
    <div id="opinie" className={styles.opinieContainer}>
      <div className={styles.opinieContent}>
        <div className={styles.header}>
        <hr className={styles.hrLine} />
        <h1>
          Opinie
        </h1>
        </div>
        {isMobile ? (
          <div ref={ref} className={`image ${styles.opinieRow} ${inView ? styles.slideIn : ''}`}>
            {[person1, person2, person3, person4].slice(currentIndex, currentIndex + 1).map((person, index) => (
              <div key={index} className={`${styles.opinia} opinia`}>
                <img src={person} alt={`Osoba ${index + 1}`} className={`${styles.personImage} ${styles[`person${index + 1}`]}`} />
                <h3>{['Aniela Tysler', 'Natalia Watkowska', 'Wojciech Szulc', 'Joanna Jaruszewska'][currentIndex + index]}</h3>
                <p>
                  {[
                    "Kasia i Witek to idealny duet profesjonalistów. Kasia zadba o  dopieszczenie wizualnej strony, od najlepszych zdjęć jakie możecie mieć po ustawienie graficzne korespondujące z oczekiwaniami klienta. Natomiast Witek dopieści to wszystko w techniczne aspekty, aby strona działa jak należy, czyli szybko, płynnie i responsywnie. Szczerze polecam.",
                    "Bardzo polecam współpracę z Kasią i Witkiem. Pomoc na każdym etapie, szybka realizacja i efekt dokładnie taki, o jakim marzyłam❤️.",
                    "Jakość pierwsza klasa - tak najprościej można opisać współpracę z " +
                    "Kasią i Witoldem.👌 Znaleźć kogoś kto postawi dobrze działającą " +
                    "stronę internetową to jedno, ale znaleźć kogoś kto zrobi to " +
                    "dodatkowo w dużej jakości wizualnej to zupełnie inna sprawa! Jestem " +
                    "wieloletnim klientem sesji biznesowych Kasi i Witka i zawsze jestem " +
                    "zadowolony z efektu końcowego. Gorąco polecam! 👍😉",
                    "Zleciłam wykonanie strony internetowej salonu kosmetycznego, jestem" +
                    "bardzo zadowolona z efektu końcowego! Wszystko wyszło tak jak" +
                    "chciałam, szczerze polecam. Projekt był szybko wykonany i dopracowany" +
                    "w każdym szczególe.",
                  ][currentIndex + index]}
                </p>
              </div>
            ))}
          </div>
        ) : (
          <div  ref={ref} className={`image ${styles.opinieRow} ${inView ? styles.slideIn : ''}`}>
            {[person1, person2, person3, person4].slice(currentIndex, currentIndex + 3).map((person, index) => (
              <div key={index} className={`${styles.opinia} opinia`}>
                <img src={person} alt={`Osoba ${index + 1}`} className={`${styles.personImage} ${styles[`person${index + 1}`]}`} />
                <h3>{['Aniela Tysler', 'Natalia Watkowska', 'Wojciech Szulc', 'Joanna Jaruszewska'][currentIndex + index]}</h3>
                <p>
                  {[
                    "Kasia i Witek to idealny duet profesjonalistów. Kasia zadba o  dopieszczenie wizualnej strony, od najlepszych zdjęć jakie możecie mieć po ustawienie graficzne korespondujące z oczekiwaniami klienta. Natomiast Witek dopieści to wszystko w techniczne aspekty, aby strona działa jak należy, czyli szybko, płynnie i responsywnie. Szczerze polecam. ",
                    "Bardzo polecam współpracę z Kasią i Witkiem. Pomoc na każdym etapie, szybka realizacja i efekt dokładnie taki, o jakim marzyłam❤️.",
                    "Jakość pierwsza klasa - tak najprościej można opisać współpracę z " +
                    "Kasią i Witoldem.👌 Znaleźć kogoś kto postawi dobrze działającą " +
                    "stronę internetową to jedno, ale znaleźć kogoś kto zrobi to " +
                    "dodatkowo w dużej jakości wizualnej to zupełnie inna sprawa! Jestem " +
                    "wieloletnim klientem sesji biznesowych Kasi i Witka i zawsze jestem " +
                    "zadowolony z efektu końcowego. Gorąco polecam! 👍😉",
                    "Zleciłam wykonanie strony internetowej salonu kosmetycznego, jestem" +
                    "bardzo zadowolona z efektu końcowego! Wszystko wyszło tak jak" +
                    "chciałam, szczerze polecam. Projekt był szybko wykonany i dopracowany" +
                    "w każdym szczególe.",
                  ][currentIndex + index]}
                </p>
              </div>
            ))}
          </div>
        )}

        <>
          <div className={styles.arrowLeft} onClick={scrollLeft}>
            &lt;
          </div>
          <div className={styles.arrowLeftL} onClick={scrollLeftL}>
            &lt;
          </div>
          <div className={styles.arrowRight} onClick={scrollRight}>
            &gt;
          </div>
          <div className={styles.arrowRightL} onClick={scrollRightL}>
            &gt;
          </div>
        </>
      </div>
    </div>
  );
};

export default Reviews;
