// App.jsx
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import LoadingScreen from './LoadingScreen/LoadingScreen';
import AboutUs from './AboutUs/AboutUs';
import Advantages from './Advantages/Advantages';
import './App.css';
import Contact from './Contact/Contact';
import CookieModal from './Cookie/Cookie';
import Cooperation from './Cooperation/Cooperation';
import Footer from './Footer/Footer';
import Hero from './Hero/Hero';
import Mission from './Mission/Mission';
import Navigation from './Navigation/Navigation';
import Offer from './Offer/Offer';
// import PhotoPortfolio from './PhotoPortfolio/PhotoPortfolio';
import Reviews from './Reviews/Reviews';
import Top from './Top/Top';
import WebPortfolio from './WebPortfolio/WebPortfolio';
import GalleryComponent from './GalleryComponent/GalleryComponent';
import { images, mobileImages } from './GalleryComponent/images';

function App() {


  return (
<Router>
          <Navigation />
          <Hero />
          <Offer />
          <WebPortfolio />
          <GalleryComponent images={images} mobileImages={mobileImages} />
          <Reviews />
          <AboutUs />
          <Cooperation />
          <Advantages />
          <Mission />
          <Top />
          <Contact />
          <Footer />
          <CookieModal />
          </Router>
  );
}

export default App;
