import React, { useEffect } from 'react';
import ScrollReveal from 'scrollreveal';
import styles from './Offer.module.css';

const Offer = () => {

  
  useEffect(() => {
    ScrollReveal().reveal('.offerBoxPhotography, .offerBoxDesign', {
      duration: 1000,
      origin: 'right',
      distance: '100px',
      easing: 'ease-in-out',
      scale: 1,
      delay: 200,
      mobile: true,
    });
  }, []);

  return (
    <div id="offer" className={styles.ofertaContainer}>
      <h2 className={styles.header}><hr className={styles.hrLine} />Oferta</h2>
      <div className={styles.column}>
        <div className={`${styles.offerBoxPhotography} offerBoxPhotography`}>
          <div className={styles.backgroundText}>
            <h3>Fotografia dla biznesu</h3>
            <p className={styles.hiddenParagraph}>Profesjonalna sesja zdjęciowa, która podkreśli Twoją markę i będzie idealnie wpasowywać się w potrzeby Twojej strony internetowej.</p>
          </div>
        </div>
        <div className={`${styles.offerBoxDesign} offerBoxDesign`}>
          <div className={styles.backgroundText}>
            <h3>Projektowanie i budowanie stron www</h3>
            <p className={styles.hiddenParagraph}>Unikalna strona internetowa, landing page, wizytówka czy portfolio, skrojona na potrzeby Twojej firmy lub marki osobistej. Strony są interaktywne, responsywne i zoptymalizowane dla przeglądarek.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Offer;
