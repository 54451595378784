import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import styles from './WebPortfolio.module.css';
import jjsalon from './jjsalon.webp';
import jdziel from './jdziel.webp';
import nwatkowska from './nwatkowska.webp';
import { Link } from 'react-router-dom';
import nataliaZrzut from './NataliaZrzut-1.webp';
import jjsalonZrzut from './jjsalonwww-1.webp';
import justynaZrzut from './jd.webp';

const WebPortfolio = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const jjsalonRef = useRef(null);
  const jdzielRef = useRef(null);
  const nwatkowskaRef = useRef(null);

  const imagesData = [
    { image: jjsalon, zrzut: jjsalonZrzut, link: "https://tubular-marshmallow-21a7e8.netlify.app" },
    { image: nwatkowska, zrzut: nataliaZrzut, link: "http://www.nataliawatkowska.pl" },
    { image: jdziel, zrzut: justynaZrzut, link: "https://65947bbf499c7311192ee9c0--glistening-nougat-8aa6e5.netlify.app/" },
  ];

  useEffect(() => {
    const handleMouseMove = (e) => {
      setCursorPosition({ x: e.clientX, y: e.clientY });
    };

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const openModal = (imageData) => {
    setSelectedImage(imageData.zrzut);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedImage('');
    setModalIsOpen(false);
  };

  const transforms = (x, y, el) => {
    let box = el.getBoundingClientRect();
    let calcX = -(y - box.y - box.height / 2) / 500;
    let calcY = (x - box.x - box.width / 2) / 500;

    return `perspective(100px) rotateX(${calcX}deg) rotateY(${calcY}deg)`;
  };

  const transformElement = (elementRef) => {
    if (elementRef.current && window.innerWidth > 768) {
      const box = elementRef.current.getBoundingClientRect();
      const distance = Math.sqrt(
        Math.pow(cursorPosition.x - (box.left + box.width / 2), 2) +
          Math.pow(cursorPosition.y - (box.top + box.height / 2), 2)
      );

      if (distance < 1000) {
        // Ustaw odległość, przy której ma zaczynać się animacja (np. 100 pikseli)
        const position = [cursorPosition.x, cursorPosition.y, elementRef.current];
        elementRef.current.style.transform = transforms.apply(null, position);
      } else {
        elementRef.current.style.transform = 'none';
      }
    }
  };

  useEffect(() => {
    window.requestAnimationFrame(() => transformElement(jjsalonRef));
    window.requestAnimationFrame(() => transformElement(jdzielRef));
    window.requestAnimationFrame(() => transformElement(nwatkowskaRef));
  }, [cursorPosition]);

  return (
    <div id="portfolio" className={styles.WebPortfolioWrapper}>
      <h2 className={styles.header}>
        <hr className={styles.hrLine} />
        Nasze realizacje
      </h2>
      <div className={styles.laptopContainer}>
        {imagesData.map((data, index) => (
          <img
            key={index}
            ref={
              data.link === "https://tubular-marshmallow-21a7e8.netlify.app"
                ? jjsalonRef
                : data.link === "https://stalwart-marigold-29f680.netlify.app/"
                ? jdzielRef
                : nwatkowskaRef
            }
            src={data.image}
            alt="Laptop"
            className={`${styles.laptopImage} ${
              data.link === "http://www.nataliawatkowska.pl" ? styles.MiddleLaptop : ''
            }`}
            onClick={() => openModal(data)}
          />
        ))}
      </div>

      {/* Modal */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className={styles.modalContent}
        overlayClassName={styles.modalOverlay}
      >
        <div className={styles.modalContainer}>
          <img src={selectedImage} alt="Podgląd strony" className={styles.modalImage} />
        </div>
        <Link to={imagesData.find((data) => data.zrzut === selectedImage)?.link || "#"} target="_blank">
  <button className={styles.btnModal}>Zobacz stronę</button>
</Link>
        <Link>
          <button className={styles.btnModal} onClick={closeModal}>
            Zamknij
          </button>
        </Link>
      </Modal>
    </div>
  );
};

export default WebPortfolio;
