import React, { useEffect, useState } from 'react';
import { Events, scrollSpy } from 'react-scroll';
import styles from './AboutUs.module.css';
import kasiaWitek from './Kasia&Witek.webp';
import ozdoba from './2.webp';

const AboutUs = () => {
  const [opacity, setOpacity] = useState(0);
  const [transformValue, setTransformValue] = useState('translateX(100%)');

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const element = document.getElementById('onas');
    const rect = element.getBoundingClientRect();
    const distanceFromTop = rect.top;

    const opacityValue = Math.max(0, Math.min(1, 1 - distanceFromTop / windowHeight));
    const transform = `translateX(${Math.max(0, distanceFromTop / 9)}px)`; // Dostosuj według potrzeb

    setOpacity(opacityValue);
    setTransformValue(transform);
  };

  useEffect(() => {
    Events.scrollEvent.register('begin', function () {
      console.log('begin', arguments);
    });

    Events.scrollEvent.register('end', function () {
      console.log('end', arguments);
    });

    scrollSpy.update();

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  }, []);

  return (
    <div id="onas" className={styles.aboutUsWrapper}>
      <div className={styles.TitleContainer}>
        <h1>
          <hr className={styles.hrLine} /> O nas
        </h1>
        <p className={styles.descriptionContainer}>
          Nasza drużyna to połączenie doświadczenia i pasji: Witek - rzetelny i niezawodny programista, Kasia -
          doświadczona fotografka specjalizująca się w fotografii wizerunkowej oraz kobieta marketingu. Razem tworzymy
          idealne połączenie, aby Twoja marka mogła zachwycić online.
        </p>
      </div>
      <div className={styles.AusImageContainer}>
        <img className={styles.kasiaWitekImage} src={kasiaWitek} alt="Opis zdjęcia" style={{ opacity: opacity, transform: transformValue }} />
      </div>
      <img className={styles.dodatek} src={ozdoba} alt="szlaczek, ozdoba zdjęcia" />
    </div>
  );
};

export default AboutUs;
