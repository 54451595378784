// images.js

import image10 from '../PhotoPortfolio/Aniela-min.webp';
import image2 from '../PhotoPortfolio/Asia27-min.webp';
import image5 from '../PhotoPortfolio/Asia38-min.webp';
import image15 from '../PhotoPortfolio/AsiaKazimierz-min.webp';
import image12 from '../PhotoPortfolio/Framble49-min.webp';
import image7 from '../PhotoPortfolio/Framble252-min.webp';
import image4 from '../PhotoPortfolio/JustynaDziel61-min.webp';
import image19 from '../PhotoPortfolio/JustynaDziel75-min.webp';
import image13 from '../PhotoPortfolio/JustynaDziel126-min.webp';
import image18 from '../PhotoPortfolio/KasiaPioch26-min.webp';
import image9 from '../PhotoPortfolio/KasiaPioch60-min.webp';
import image1 from '../PhotoPortfolio/milkadavid-min.webp';
import image3 from '../PhotoPortfolio/milkadavid2-min.webp';
import image11 from '../PhotoPortfolio/milkadavid3-min.webp';
import image17 from '../PhotoPortfolio/sesjaRhino262-min.webp';
import image8 from '../PhotoPortfolio/sesjaRhino275-min.webp';
import image14 from '../PhotoPortfolio/sesjaRhino544-min.webp';
import image6 from '../PhotoPortfolio/JustynaDziel105-min.webp';


import image100 from '../PhotoPortfolio/Aniela-min_27_11zon.webp';
import image20 from '../PhotoPortfolio/Asia27-min_26_11zon.webp';
import image50 from '../PhotoPortfolio/Asia38-min_25_11zon.webp';
import image150 from '../PhotoPortfolio/AsiaKazimierz-min_22_11zon.webp';
import image120 from '../PhotoPortfolio/Framble49-min_18_11zon.webp';
import image70 from '../PhotoPortfolio/Framble252-min_17_11zon.webp';
import image40 from '../PhotoPortfolio/JustynaDziel61-min_15_11zon.webp';
import image190 from '../PhotoPortfolio/JustynaDziel75-min_14_11zon.webp';
import image180 from '../PhotoPortfolio/KasiaPioch26-min_11_11zon.webp';
import image90 from '../PhotoPortfolio/KasiaPioch60-min_10_11zon.webp';
import image156 from '../PhotoPortfolio/milkadavid-min_3_11zon.webp';
import image30 from '../PhotoPortfolio/milkadavid2-min_7_11zon.webp';
import image110 from '../PhotoPortfolio/milkadavid3-min_2_11zon.webp';
import image170 from '../PhotoPortfolio/sesjaRhino262-min_6_11zon.webp';
import image80 from '../PhotoPortfolio/sesjaRhino275-min_5_11zon.webp';
import image140 from '../PhotoPortfolio/sesjaRhino544-min_4_11zon.webp';
import image60 from '../PhotoPortfolio/JustynaDziel105-min_13_11zon.webp';

const images = [
  image1,
  image2,
  image8,
  image15,
  image12,
  image19,
  image18,
  image7,
  image10,
  image4,
  image9,
  image13,
  image3,
  image11,
  image17,
  image5,
  image14,
  image6,
];


// images.js


const mobileImages = [
  image156,
  image20,
  image80,
  image150,
  image120,
  image190,
  image180,
  image70,
  image100,
  image40,
  image90,
  image13,
  image30,
  image110,
  image170,
  image50,
  image140,
  image60,
];

export  { images, mobileImages };
