import React, { useEffect, useRef } from "react";
import styles from "./Hero.module.css";
import plama from "./plama3.webp";
import kasiaWitekHero from "./kasiaWitekHero.webp";
import ozdoba from "./1.png";
import ScrollReveal from "scrollreveal";

const Hero = () => {
  const textRef = useRef(null);
  const dodatekRef = useRef(null);

  useEffect(() => {
    // Konfiguracja dla tekstu
    ScrollReveal().reveal(textRef.current, {
      origin: "left",
      distance: "450px",
      easing: 'ease-in-out',
      duration: 1300,
      delay: 400,
    });

    // Konfiguracja dla dodatku
    ScrollReveal().reveal(dodatekRef.current, {
      origin: "left",
      distance: "300px",
      easing: 'ease-in-out',
      duration: 1300,
      delay: 500,
    });
  }, []);

  return (
    <div id="/" className={styles.heroWrapper}>
      <div className={styles.textContainer} ref={textRef}>
        <h1>
          Strony www <br />i fotografia <br />
          dla biznesu
        </h1>
        <p className={styles.description}>
          Stworzymy Twoją <br></br> wizytówkę w sieci!
        </p>
      </div>
      <div className={styles.imageWrapper}>
        <img className={styles.image} src={plama} alt="Opis zdjęcia" loading="eager"/>
      </div>
      <div className={styles.imageContainer} ref={dodatekRef}>
        <img className={styles.kasiaWitekImage} src={kasiaWitekHero} alt="Opis zdjęcia" />
      </div>
      <img className={styles.dodatek} src={ozdoba} alt="szlaczek, ozdoba zdjęcia" />
    </div>
  );
};

export default Hero;
