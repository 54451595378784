import React from 'react';
import { useInView } from 'react-intersection-observer';
import styles from './Mission.module.css';
import ozdobaMission from "./3.png";

const Mission = () => {

  const [ref, inView] = useInView({
    triggerOnce: true, 
  });

  return (
    <div className={`image ${styles.container} ${inView ? styles.slideIn : ''}`}>
      <div ref={ref} className={`${styles.leftColumnMission} leftColumndd`}>
      <hr className={styles.hrLine} />
        <h1>Nasza misja  </h1>
        <div>
        <img className={styles.ozdobaMission} src={ozdobaMission} alt="szlaczek, ozdoba zdjęcia" />
      </div>
      </div>
      <div className={`${styles.rightColumnMission} rightColumndd`}> 
        <p>Naszą misją jest pomagać klientom osiągnąć sukces online, tworząc strony internetowe, które nie tylko zachwycają unikalnym wyglądem, ale także generują wartość, zwiększają widoczność i przyciągają nowych klientów.</p>
      </div>
    </div>
  );
};

export default Mission;
