import React from 'react';
import styles from './Cooperation.module.css';
import { useInView } from 'react-intersection-observer';
import picture from './3.webp';
import picture2 from './4.webp';
import picture3 from './5.webp';

const Cooperation = () => {
  
  const [ref, inView] = useInView({
    triggerOnce: true, 
  });


  return (
    <div id="wspolpraca"
      className={styles.container}
    >
      <div className={styles.headerWrapper}>
      <hr ref={ref} className={styles.hrLine} />
      <h1 className={styles.header}>Jak wygląda współpraca?
      </h1>
      </div>
      <div
        className={styles.imagesContainerAbout}
      >
        <div className={`image ${styles.image} ${inView ? styles.slideIn : ''}`}>
          <img src={picture} alt="Opis zdjecia 1" />
          <p>
            Spotkajmy się przy kawie, omówmy Twoje potrzeby i nasze wspólne pomysły na idealną stronę www dla
            Twojej marki.
          </p>
        </div>
        <div className={`image ${styles.image} ${inView ? styles.slideIn1 : ''}`}>
          <img src={picture2} alt="Opis zdjecia 2" />
          <p>
            Przygotowanie zdjęć, projektu, treści. Jesteśmy wsparciem w obszarze wizualnym, ale także strategii
            komunikacji. Zależy nam, żeby Twoja strona była dla Ciebie zyskownym narzędziem w biznesie!
          </p>
        </div>
        <div className={`image ${styles.image} ${inView ? styles.slideIn2 : ''}`}>
          <img src={picture3} alt="Opis zdjecia 3" />
          <p>Szybka realizacja, stworzenie strony zajmuje średnio 2 tygodnie od zatwierdzenia ostatecznego projektu.</p>
        </div>
      </div>
    </div>
  );
};

export default Cooperation;
