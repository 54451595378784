
import React from 'react';

const ZwinButton = ({ onClick }) => (

    <svg
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 24 24"
    height="48px"
    viewBox="0 0 24 24"
    width="48px"
    fill="rgb(3, 76, 71)"
  >
    <g>
      <rect fill="none" height="24" width="24" />
    </g>
    <g>
      <g>
        <polygon points="6,17.59 7.41,19 12,14.42 16.59,19 18,17.59 12,11.59" />
        <polygon points="6,11 7.41,12.41 12,7.83 16.59,12.41 18,11 12,5" />
      </g>
    </g>
  </svg>
)

export default ZwinButton;