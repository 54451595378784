import React from "react";
import styles from "./Footer.module.css";
import { useInView } from 'react-intersection-observer';
import logo from "./logoDark.webp";
import footerImage from "./6.webp";
import { Link } from "react-router-dom";


const Footer = () => {

  const [ref, inView] = useInView({
    triggerOnce: true, 
  });

  return (
    <footer className={styles.footer}>
      <div ref={ref} className={`${styles.leftColumnFooter} leftColumn`}>
        <div className={`image ${styles.logoWrapper} ${inView ? styles.slideIn : ''}`}>
          <img className={`${styles.logoImageFooter} logoImage`} src={logo} alt="logo"/>
        </div>
        <div className={styles.textWrapper}>
          <h1>
            Zacznijmy <br></br> wspólny projekt
          </h1>
          <p>
            Katarzyna Nawrot <br />{" "}
            <Link to="tel:+48505498337">+48 505-498-337</Link>
          </p>

          <p>
            Witold Nawrot<br></br>
            <Link to="tel:+48727913230">+48 727-913-230</Link>
          </p>

          <div className={styles.mail}>
            <Link to="mailto:kontakt@visualfusion.pl">kontakt@visualfusion.pl</Link>
          </div>
        </div>
      </div>
      <div className={`image ${styles.rightColumnFooter} ${inView ? styles.slideIn1 : ''}`}>
        <img src={footerImage} alt="Zdjęcie"/>
      </div>
    </footer>
  );
};

export default Footer;
