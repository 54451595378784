// GalleryComponent.jsx

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Modal from 'react-modal';
import { MdArrowBack, MdArrowForward } from 'react-icons/md';
import styles from './GalleryComponent.module.css';
import WiecejButton from './WiecejButton'; 
import ZwinButton from './Zwin';
import { images, mobileImages } from './images';

Modal.setAppElement('#root');

const GalleryComponent = ({ images, mobileImages }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showAllImages, setShowAllImages] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const openModal = (image, index) => {
    setSelectedImage(image);
    setCurrentIndex(index);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const toggleImages = () => {
    setShowAllImages(!showAllImages);
  };

  const getDisplayedImages = () => {
    return showAllImages || !isMobile ? images : images.slice(0, Math.ceil(images.length * 0.6));
  };

  const handlePrevImage = () => {
    const prevIndex = (currentIndex - 1 + getDisplayedImages().length) % getDisplayedImages().length;
    setCurrentIndex(prevIndex);
    setSelectedImage(getDisplayedImages()[prevIndex]);
  };

  const handleNextImage = () => {
    const nextIndex = (currentIndex + 1) % getDisplayedImages().length;
    setCurrentIndex(nextIndex);
    setSelectedImage(getDisplayedImages()[nextIndex]);
  };

  const handleKeyDown = (e) => {
    if (showModal) {
      switch (e.key) {
        case 'ArrowLeft':
          handlePrevImage();
          break;
        case 'ArrowRight':
          handleNextImage();
          break;
        case 'Escape':
          closeModal();
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const isMobileDevice = window.matchMedia('(max-width: 768px)').matches;
      setIsMobile(isMobileDevice);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [showModal, currentIndex]);

  return (
    <div className={styles.masonryGalleryContainer}>
      <ImageList variant="masonry" cols={isMobile ? 2 : 4} gap={8}>
        <h1>
          <hr className={styles.hrLine} />
          Fotografia <br />dla biznesu
        </h1>
        {getDisplayedImages().map((image, index) => (
          <ImageListItem key={index} onClick={() => openModal(image, index)}>
            <img
              className={styles.photo}
              srcSet={isMobile ? `${mobileImages[index]}?w=124&fit=crop&auto=format&dpr=2 2x` : `${image}?w=248&fit=crop&auto=format&dpr=2 2x`}
              src={isMobile ? `${mobileImages[index]}?w=124&fit=crop&auto=format` : `${image}?w=248&fit=crop&auto=format`}
              alt={`Image ${index}`}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
      {isMobile && (
        <div className={styles.buttonWrapper}>
          {showAllImages ? (
            <ZwinButton
              className={styles.showAllButton}
              onClick={toggleImages}
            />
          ) : (
            <WiecejButton
              className={styles.showAllButton}
              onClick={toggleImages}
            />
          )}
        </div>
)}

      <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        contentLabel="Selected Image"
        className={styles.modal}
        overlayClassName={styles.modalOverlay}
        shouldCloseOnOverlayClick={!isMobile}
        shouldCloseOnEsc={!isMobile}
      >
        <span className={styles.closeButton} onClick={closeModal}>&times;</span>
        <MdArrowBack className={styles.arrowLeft} onClick={handlePrevImage} />
        <MdArrowForward className={styles.arrowRight} onClick={handleNextImage} />
        <div className={styles.modalContent}>
          {selectedImage && (
            <img src={selectedImage} alt="Selected Image" className={styles.modalImage} />
          )}
        </div>
      </Modal>
    </div>
  );
};

GalleryComponent.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  mobileImages: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default GalleryComponent;
