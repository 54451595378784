
import React from 'react';

const WiecejButton = ({ onClick }) => (

<svg
                xmlns="http://www.w3.org/2000/svg"
                height="48px"
                viewBox="0 0 24 24"
                width="48px"
                fill="#000000"
              
                onClick={onClick}
              >
                <circle
                  className="bounce"
                  cx="12"
                  cy="12"
                  r="11"
                  stroke="rgb(3, 76, 71)"
                  strokeWidth="2"
                  fill="none"
                />
                <path d="M0 0h24v24H0z" fill="none" />
                <path
                  d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
                  fill="rgb(3, 76, 71)"
                />
              </svg>
)

export default WiecejButton;