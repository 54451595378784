import React from 'react';
import styles from './polityka.module.css';

const Polityka = () => {
  return (
    <div className={styles.privacyPolicy}>
      <h1>Polityka Prywatności</h1>
      <p>
        Dziękujemy za odwiedzenie naszej strony internetowej. Niniejsza Polityka Prywatności ma na celu wyjaśnienie, w jaki sposób gromadzimy, używamy, ujawniamy i chronimy Twoje dane osobowe podczas korzystania z naszej witryny.
      </p>
      <h3>2. Rodzaje Zebranych Informacji</h3>
<p>a. Dane osobowe
Podczas korzystania z naszej strony internetowej możemy zbierać pewne dane osobowe, takie jak imię, nazwisko, adres e-mail itp. Możemy również zbierać informacje o Twoim urządzeniu, przeglądarce internetowej i adresie IP.

b. Dane anonimowe
Mamy również możliwość zbierania danych anonimowych, które nie pozwalają na identyfikację konkretnych osób. Przykłady to informacje o tym, jak korzystasz z naszej strony internetowej, jakie strony odwiedzasz itp.
</p>
<h3>3. Cel Zbierania Danych</h3>
<p>Zbieramy dane w celu:

Zapewnienia dostępu do naszej witryny i świadczenia usług;
Personalizacji doświadczenia użytkownika;
Analizy ruchu na stronie i doskonalenia naszych usług.
</p>
<h3>4. Używanie plików cookies</h3>
<p>Nasza strona może korzystać z plików cookies w celu ułatwienia nawigacji i zbierania informacji o użytkownikach. Możesz zmienić ustawienia plików cookies w swojej przeglądarce.
</p>
<h3>5. Udostępnianie Danych Osobowych</h3>
<p>Nie udostępniamy Twoich danych osobowych osobom trzecim bez Twojej zgody, chyba że wymaga tego prawo.</p>

<h3>6. Bezpieczeństwo Danych</h3>
<p>Podjęliśmy odpowiednie środki bezpieczeństwa, aby chronić Twoje dane przed utratą, nieautoryzowanym dostępem lub ujawnieniem.</p>

<h3>7. Zmiany w Polityce Prywatności</h3>
<p>Zastrzegamy sobie prawo do aktualizacji naszej Polityki Prywatności w dowolnym momencie. Informacje o zmianach będą publikowane na tej stronie.
</p>
<h3>8. Kontakt</h3>
<p>Jeśli masz pytania dotyczące naszej Polityki Prywatności, prosimy o kontakt pod adresem [adres e-mail].

Dziękujemy za zrozumienie i zaufanie.

Data wejścia w życie: 27.12.2023r.
</p>
    </div>
  );
};

export default Polityka;
