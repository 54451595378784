// Navigation.js
import React, { useState } from 'react';
import { Link } from 'react-scroll';
import Stack from '@mui/material/Stack';
import logo from './logo.png';
import styles from './Navigation.module.css';
import { Squash as Hamburger } from 'hamburger-react';

const Navigation = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const handleToggleMenu = () => {
    setMenuOpen(!isMenuOpen);
    setOpen(!isOpen);
  };

  const handleLinkClick = () => {
    // Zamknij menu po kliknięciu w link
    setMenuOpen(false);
    setOpen(false);
  };

  return (
    <nav className={`${styles.navigation} ${isMenuOpen ? styles.open : ''}`}>
      <div className={styles.logo}>
        <Link
          activeClass="active"
          to="/"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          href="#/"
          onClick={handleLinkClick}
        >
          <img className={styles.logoImage} src={logo} alt="logo" />
        </Link>
      </div>
      <div className={`${styles.navItems} ${isMenuOpen ? styles.showMenu : ''}`}>
        <ul className={styles.navBar}>
          <li>
            <Link
              activeClass="active"
              to="/"
              href="#/"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              onClick={handleLinkClick}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="offer"
              href="#offer"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              onClick={handleLinkClick}
            >
              Oferta
            </Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="portfolio"
              href="#portfolio"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              onClick={handleLinkClick}
            >
              Portfolio
            </Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="onas"
              href="#onas"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              onClick={handleLinkClick}
            >
              O nas
            </Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="wspolpraca"
              href="#wspolpraca"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              onClick={handleLinkClick}
            >
              Współpraca
            </Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="opinie"
              href="#opinie"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              onClick={handleLinkClick}
            >
              Opinie
            </Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="kontakt"
              href="#kontakt"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              onClick={handleLinkClick}
            >
              Kontakt
            </Link>
          </li>
        </ul>
      </div>
      <div className={styles.burgerMenu} onClick={handleToggleMenu}>
        <Hamburger toggled={isOpen} toggle={setOpen}></Hamburger>
      </div>
    </nav>
  );
};

export default Navigation;
